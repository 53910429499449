export default new class ThreejsPipeline {
  name = 'threejsrenderer'

  setExperience = experience => {
    this.experience = experience
  }

  onStart = ({ canvas, canvasWidth, canvasHeight, GLctx }) => {
    const scene = new window.THREE.Scene()
    const camera = new window.THREE.PerspectiveCamera(80, canvasWidth / canvasHeight, 0.5, 10000)
    camera.position.setY(1.6)
    scene.add(camera)

    const renderer = new window.THREE.WebGLRenderer({ canvas, context: GLctx, alpha: false, antialias: true })
    renderer.autoClear = false
    renderer.setSize(canvasWidth, canvasHeight)

    this.scene3 = { scene, camera, renderer }
  }

  onUpdate = ({ processCpuResult }) => {
    if (this.experience && this.experience.onXRUpdate) {
      this.experience.onXRUpdate({ processCpuResult })
    } else {
      if (!processCpuResult.reality) {
        return
      }
  
      const { rotation, position, intrinsics } = processCpuResult.reality
      const { camera } = this.scene3

      for (let i = 0; i < 16; i++) {
        camera.projectionMatrix.elements[i] = intrinsics[i]
      }
  
      if (camera.projectionMatrixInverse) {
        camera.projectionMatrixInverse.copy(camera.projectionMatrix).invert()
      }
  
      if (rotation) {
        camera.setRotationFromQuaternion(rotation)
      }
  
      if (position) {
        camera.position.set(position.x, position.y, position.z)
      }
  
      if (window.location.hash.toLowerCase() === '#dev') {
        camera.rotation.x = 0
      }
    }
  }

  onCanvasSizeChange = ({ canvasWidth, canvasHeight }) => {
    this.scene3.renderer.setSize(canvasWidth, canvasHeight)
  }

  onRender = () => {
    const { scene, renderer, camera } = this.scene3
    renderer.clearDepth()
    renderer.render(scene, camera)
  }

  xrScene = () => this.scene3

  onInitScene = cb => {
    this.callbacks.push(cb)
  }
}()