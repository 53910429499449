import ThreejsPipeline from './ThreejsPipeline'


export default new class ThreeARPipeline {
  constructor () {
    this.name = 'three-ar-pipeline'
  }

  setExperience = experience => {
    this.experience = experience
  }
  
  onLoadEvent = cb => {
    (this.loadEvents = this.loadEvents || []).push(cb)
  }

  onStart = ({ canvas, canvasWidth, canvasHeight }) => {
    
      const { scene, camera, renderer } = ThreejsPipeline.xrScene()// window.XR8.Threejs.xrScene()
      this.scene = scene
      this.camera = camera
      this.renderer = renderer
      
      if(this.experience && this.experience.onStart){
        this.experience.onStart(this.camera.position, this.camera.quaternion)
      }

      window.XR8.XrController.updateCameraProjectionMatrix({
        origin: this.camera.position,
        facing: this.camera.quaternion
      })

      if (window.XRStarted !== true) {
        document.querySelector('html').classList.add('launched')
        window.XRStarted = true
      }

  }

  onException = (error) => {
    //error
    // const customError = document.querySelector('#runtimeErrorContainer .floater')
    // customError.src = ErrorLogo
    
  }

  onDetach = () => {
    if (this.experience && this.experience.onDetach){
      this.experience.onDetach()
    }
  }

  onUpdate = data => {
    if (this.renderer) {
      this.camera.updateMatrixWorld()
      this.camera.matrixWorldInverse.copy(this.camera.matrixWorld).invert()
      this.renderer.clear(true, true, true)
    }
  }

  onRender = () => {
    if (this.experience && this.experience.onRender) {
      this.experience.onRender()
    }
    if(this.experience) this.experience.update();
  }

}()