import React from 'react'
import s from './Rotate.module.scss'


export default function Rotate(props) {
  return (
    <div className={s.Rotate}>      
      <h1>Please rotate your device to enjoy this experience.</h1>
    </div>
  )
}
