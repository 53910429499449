import React, { forwardRef } from 'react'
import s from './Modal.module.scss'
import Div100vh from 'react-div-100vh'

// export default function Modal(props) {
export default forwardRef((props, ref) => {

  function handleCloseClick () {
    if (props.closeHandler) {
      props.closeHandler()
    }
  }

  return (
    <Div100vh className={s.Modal}>
      <div className={s.ModalContainer} ref={ref}>
        <div className={s.close} onClick={() => handleCloseClick()}></div>
        <div className={s.content}>
          { props.copy.map((paragraph, index) => (
            <p key={`modal-copy-${index}`} dangerouslySetInnerHTML={{__html: paragraph}}></p>
          ))}
          { props.button && (
            <div className="button" onClick={() => props.button.callback()}>
              {props.button.label}
            </div>
          )}
        </div>
      </div>
    </Div100vh>
  )
})
