import React, { useState, useEffect, useRef, forwardRef } from 'react'
import s from './Artists.module.scss'
import Div100vh from 'react-div-100vh'
import {ReactComponent as Instagram} from '../../assets/svg/instagram.svg'
import {ReactComponent as Twitter} from '../../assets/svg/twitter.svg'
import {ReactComponent as Tiktok} from '../../assets/svg/tiktok.svg'
import {ReactComponent as Website} from '../../assets/svg/website.svg'
import { CSSTransition } from 'react-transition-group'


// export default function Artists(props) {
export default forwardRef((props, ref) => {
  
  const [current, setCurrent] = useState(props.current)
  const [showArtist, setShowArtist] = useState(false)
  const artistList = useRef(null)

  useEffect(() => {
    setCurrent(props.current)
    if (props.current === -1) {
      setShowArtist(false)
    }
  }, [props])

  function onArtistClicked(index) {
    props.artistSelected(index)
  }

  function onArtistsOut() {
    setShowArtist(true)
  }
  
  const artists =  [
    {
      name: 'Andrea Bergart',
      image: require('../../assets/images/Andrea_BIO_Image.jpg'),
      bio: [
        'Andrea Bergart is a visual artist and designer based in New York City. She\'s inspired by high fashion, basketball culture, and her involvement in the all-women basketball community, Downtown Girls Basketball. Bergart\'s basketball handbag line was born from feeling confident as she carried a ball around New York City, showing the world she was a hooper on her way to practice.',
        'Bergart makes art and plays basketball in the same way — in the flow. To create "Trident," she allowed acrylic paint to pour, run, bleed, and be controlled by wax boundaries. She responded intuitively as the paint traveled, keeping her head clear and open for the next move.',
      ],
      
      links: [
        {
          Icon: Instagram,
          url: 'https://www.instagram.com/andreabergart/',
        },
        {
          Icon: Website,
          url: 'https://www.andreabergart.com/'
        },
      ]
    },
    {
      name: 'Ben Labuzzetta',
      image: require('../../assets/images/Ben_BIO_Image.jpg'),
      bio: [
        'When COVID-19 shut down school and sports, Ben Labuzzetta decided to focus on his art. Labuzzetta paints celebrities like Billie Eilish and Morgan Freeman as well as athletes like Jason Tatum, LeBron James, and Kobe Bryant. With the success of his online store, Labuzzetta became a full-time artist following high school and plans to build his own art studio in the next few years.',
        'Labuzzetta\'s design for his 1 World/1 People sneaker pack was inspired by Kyrie Irving\'s messages of unity and creativity.',
      ],
      
      links: [
        {
          Icon: Tiktok,
          url: 'https://www.tiktok.com/@artboy200',
        },
        {
          Icon: Instagram,
          url: 'https://www.instagram.com/ben.labuzzetta/',
        },
        {
          Icon: Website,
          url: 'https://benlabuzzetta.com/'
        },
      ]
    },
    {
      name: 'Elijah Rutland',
      image: require('../../assets/images/Elijah_BIO_Image.jpg'),
      bio: [
        'Elijah Rutland\'s love for art started in kindergarten, with epic drawings of superheroes, and evolved into sketching shoe designs in elementary school when his parents wouldn\'t buy him the expensive sneakers he wanted. Now, Rutland is the founder of Fix My Sole, a sneaker customization company, and a senior at Florida A&M University. After graduation, Rutland plans to work in the animation industry while working toward his ultimate goal of becoming an independent visual artist, designer, and director.',
        'Kyrie Irving\'s relentless pursuit of greatness inspired "Brooklyn Rolf." Rutland wanted to capture Irving\'s competitive spirit through a recurring character in his artwork, Black Rolf.'
      ],
      
      links: [
        {
          Icon: Twitter,
          url: 'https://twitter.com/fixmysole?lang=en',
        },
        {
          Icon: Instagram,
          url: 'https://www.instagram.com/fixmysole/?hl=en',
        },
        {
          Icon: Website,
          url: 'https://fixmysole.com/'
        },
      ]
    },
    {
      name: 'Gabby Anderson',
      image: require('../../assets/images/Gabby_BIO_Image.jpg'),
      bio: [
        'Gabrielle Anderson takes ordinary sneakers and gives them a story. At 17-years-old, Anderson is an accomplished IMG Academy athlete and business owner. To Anderson, her custom shoe company, Graffiti by Gabby, is more than fun footwear — it’s about making a blank canvas her own.',
        '“Dreams in Color” pays homage to Coach Dawn Staley, an American basketball Hall of Fame coach and player as well as a three-time Olympic gold medalist, who laid the foundation for aspiring female hoopers.',
      ],
      links: [
        {
          Icon: Twitter,
          url: 'https://twitter.com/GabbyAnderson04',
        },
        {
          Icon: Instagram,
          url: 'https://www.instagram.com/gabbyanderson04/',
        },
        {
          Icon: Website,
          url: 'https://www.graffitibygabby.com/'
        }
      ]
    },
    {
      name: 'No Kings Collective',
      image: require('../../assets/images/NOKC_BIO_Image-cropped.jpg'),
      bio: [
        'Brandon Hill and Peter Chang are the artists behind No King Collective, a design-build studio, creative agency, and events production company based in Washington, DC. The duo have been transforming city spaces and delivering authentic experiences since 2009.',
        'To Hill, baseball carries a certain amount of nostalgia that makes it unique from other pro-sports. He wanted to bring that feeling into the ballpark through the "Nationals" mural by fusing vintage and modern ideas about the sport with specific elements from the District’s baseball history with the early Washington Senators and Negro League Grays. He layered elements from old posters, sports playbills, and baseball cards with modern photography, contemporary typography, and original art elements. The story within the mural moves from left to right and is an homage to the past framed in a forward-looking space.'
      ],
      
      links: [
        {
          Icon: Twitter,
          url: 'https://twitter.com/NoKingsDC',
        },
        {
          Icon: Instagram,
          url: 'https://www.instagram.com/nokingscollective/',
        },
        {
          Icon: Website,
          url: 'http://www.nokingscollective.com/'
        },
      ]
    },
    {
      name: 'Rachel Motley',
      image: require('../../assets/images/Rachel_BIO_Image.jpg'),
      bio: [
        'Rachel Motley is an emerging digital illustrator at Howard University in Washington, DC. Motley has worked on projects NBA All Star Weekend 2021, Coach Dream Week, and ESPN\'s College Gameday Week 0 Announcement. After graduating, she hopes to continue as an independent artist, creating products ranging from apparel and footwear to home decor.',
        '"Head In the Clouds" is a conceptualization of how Motley feels when she creates. For Motley, the creation process places her on top of the world. It provides a sense of elevation and distracts her from the noise of her surroundings. Creating is her "happy place."',
      ],
      
      links: [
        {
          Icon: Twitter,
          url: 'https://twitter.com/_Therealrjm',
        },
        {
          Icon: Instagram,
          url: 'https://www.instagram.com/therealrjm/',
        },
        {
          Icon: Website,
          url: 'https://www.therealrjm.com/'
        },
      ]
    },
  ]

  return (
    <Div100vh ref={ref} className={`${s.Artists} ${!showArtist ? s.listMode : ''}`}>    
      <CSSTransition
        in={current === -1}
        timeout={{appear: 900, enter:900, exit: 300}}
        classNames='artist-list'
        appear={true}
        unmountOnExit
        onExited={onArtistsOut}
        nodeRef={artistList}
      >
        <div ref={artistList} className={s.artistList}>
            {artists.map(({name}, index) => (
              <h1 key={name} className={s.artistName} onClick={() => onArtistClicked(index)}>{name}</h1>
            ))}
        </div>
      </CSSTransition>
      { showArtist && current !== -1 && (
        <div className={s.artistContent}>
          <img className={s.profileImage} src={artists[current].image.default} alt={`${artists[current].name} profile`}/>
          <h1>{artists[current].name}</h1>
          <div className="divider"/>
          {artists[current].bio.map((paragraph, index) => (
            <p key={`${artists[current].name}-p-${index}`}>{paragraph}</p>
          ))}
          <div className="divider"/>
          <div className={s.social}>
            {artists[current].links.map(({url, Icon}, index) => (
              <a key={`${artists[current].name}-social-${index}`} className={s.icon} href={url} target="_blank" rel="noreferrer">
                <Icon/>
              </a>
            ))}
          </div>
        </div>
      )}
    </Div100vh>
  )
})
