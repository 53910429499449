import React, { useState, useGlobal, forwardRef } from 'reactn'
import s from './CourtSelected.module.scss'
import Div100vh from 'react-div-100vh'
import { useHistory } from 'react-router-dom'
import {ReactComponent as CourtSmall} from '../../assets/svg/court-small.svg'
import {ReactComponent as MapPin} from '../../assets/svg/map-pin.svg'
import {ReactComponent as Arrow} from '../../assets/svg/arrow.svg'


// export default function CourtSelected(props) {
  export default forwardRef((props, ref) => {
  const history = useHistory()
  const [screen, setScreen] = useState('initial')
  const [, setCurrentPage] = useGlobal('currentPage')

  function onContinueClick() {
    if (screen === 'centerCourt') {
      setCurrentPage('gallery')
      history.push('/gallery')
      return
    }
    setScreen('centerCourt')
  }

  return (
    <Div100vh className={`${s.CourtSelected} ${s[screen]}`} ref={ref}>      
        <div className={s.mainContent}>
        { screen === 'initial' ? (
          <div>
            <div className={s.copy}>
              <p>Welcome to the Gallery K11 experience at</p>
            </div>
            <div className={s.selected}>
              <span>{props.court}</span>
            </div>
          </div>
        ) : (          
          <div className={`${s.copy} ${s.centerCourtCopy}`}>
            <p>Stand at center court and view the art through your phone.</p>
          </div>
        )}
        <div className={s.courtContainer}>
          <CourtSmall className={s.court}/>
          <MapPin className={s.pin}/>
          <Arrow className={s.arrow}/>
        </div>
        <div className="button" onClick={onContinueClick}>
          Continue
        </div>
      </div>
    </Div100vh>
  )
})
