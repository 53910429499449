import React, { useState, useEffect, useRef, forwardRef } from 'react'
import s from './Menu.module.scss'
import {ReactComponent as Back} from '../../assets/svg/back.svg'
import {ReactComponent as AboutIcon} from '../../assets/svg/about-icon.svg'
import {ReactComponent as FeaturedArtists} from '../../assets/svg/featured-artists.svg'
import {ReactComponent as ShopIcon} from '../../assets/svg/shop-icon.svg'
import About from 'components/About'
import Artists from 'components/Artists'
import { CSSTransition } from 'react-transition-group'

// export default function Menu(props) {
export default forwardRef((props, ref) => {

  const [currentState, setCurrentState] = useState('collapsed')
  const [currentView, setCurrentView] = useState('menu')
  const [currentArtist, setCurrentArtist] = useState(-1)
  const expandedMenu = useRef(null)
  // const artists = useRef(null)
  const about = useRef(null)

  useEffect(() => {
    if (currentState === 'collapsed') {
      setCurrentView('menu')
    }
  }, [currentState])

  function onHamburgerClick() {
    const newState = currentState === 'expanded' ? 'collapsed' : 'expanded'
    setCurrentArtist(-1)
    setCurrentState(newState)
  }

  function onBackClick() {
    if (currentView === 'artists' && currentArtist !== -1) {
      setCurrentArtist(-1)
      return
    }
    setCurrentView('menu')
  }

  function onArtistSelected(index) {
    setCurrentArtist(index)
  }

  function onShowAboutClick() {
    setCurrentView('about')
  }

  function onShowFeaturedArtistsClick() {
    setCurrentView('artists')
  }

  function onGoHomeClick() {
    //   history.push('/')
    window.location.href = `${window.location.origin}/`
  }

  function onCopKyriesClick() {
    window.open("https://www.footlocker.com/category/collection/nike/kyrie.html", '_blank')
  }

  const menuItems = [
    {
      label: 'About<br/>Gallery K11',
      Icon: AboutIcon,
      handler: onShowAboutClick,
    },
    {
      label: 'featured<br/>artists',
      Icon: FeaturedArtists,
      handler: onShowFeaturedArtistsClick,
    },
    {
      label: 'COP a pair<br/>of KYRIes',
      Icon: ShopIcon,
      handler: onCopKyriesClick,
    },
    {
      label: 'back to<br/>home screen',
      Icon: Back,
      handler: onGoHomeClick,
    },
  ]

  return (
    <div className={`${s.Menu} ${s[currentState]}`} ref={ref}>
        <CSSTransition
          nodeRef={expandedMenu}
          in={currentState === 'expanded' && currentView === 'menu'}
          timeout={300}
          classNames='fade'
          appear={true}
          unmountOnExit
        >
          <div ref={expandedMenu} className={s.menuContent}>
            {menuItems.map(({label, Icon, handler}, index) => (
              <div key={`menuitem-${index}`} className={s.menuItem} onClick={handler}>
                <div className={s.label} dangerouslySetInnerHTML={{__html: label}}></div>
                <Icon className={s.icon}/>
              </div>
            ))}
          </div>
        </CSSTransition>
      { (currentState === 'expanded' && currentView === 'artists') && (
        <Artists current={currentArtist} artistSelected={onArtistSelected} />
      )}
      <CSSTransition
          nodeRef={about}
          in={currentState === 'expanded' && currentView === 'about'}
          timeout={300}
          classNames='fade'
          appear={true}
          unmountOnExit
        >
          <About ref={about}/>
        </CSSTransition>
      <div className={s.hamburger} onClick={onHamburgerClick}>
        <span></span>
      </div>
      { (currentState === 'expanded' && currentView !== 'menu') && (
        <div className={`${s.back} ${s.menuItem}`} onClick={onBackClick}>
          <Back/>
          <span className={s.label}>Back</span>
        </div>
      )}
    </div>
  )
})
