import ThreeARPipeline from "scripts/pipeline/ThreeARPipeline";
import ThreejsPipeline from "scripts/pipeline/ThreejsPipeline";
import * as THREE from 'three'
import { customizeUI, customizePreview, addTracking } from 'scripts/util/8thwall'

window.THREE = THREE

export default new (class {
  load = () => {
    return new Promise((resolve) => {
      Promise.all([
        new Promise((resolve) => {
          const interval = setInterval(() => {
            if (window.XR8 && window.XRExtras) {
              clearInterval(interval);
              resolve();
            }
          }, 50);
        }),
      ]).then(() => {
        window.XRExtras.Loading.showLoading()
        customizeUI()
        this.initPipeline().then(resolve)
      })
    })
  }

  initPipeline = () => new Promise(resolve => {

    window.XR8.XrController.configure({ 
      disableWorldTracking: false,
    })

    window.XR8.addCameraPipelineModules([
      window.XR8.GlTextureRenderer.pipelineModule(),      // Draws the camera feed.
      // window.XR8.Threejs.pipelineModule(),  
      ThreejsPipeline,              // Creates a ThreeJS AR Scene.
      window.XR8.XrController.pipelineModule(),           // Enables SLAM tracking.
      window.XRExtras.AlmostThere.pipelineModule(),       // Detects unsupported browsers and gives hints.
      window.XRExtras.FullWindowCanvas.pipelineModule(),  // Modifies the canvas to fill the window.
      window.XRExtras.Loading.pipelineModule(),           // Manages the loading screen on startup.
      window.XRExtras.RuntimeError.pipelineModule(),      // Shows an error image on runtime error.
      window.XR8.CanvasScreenshot.pipelineModule(), 
      ThreeARPipeline,
    ])

    window.XRExtras.MediaRecorder.configure({
      fileNamePrefix: `nike-gallery`,
    })
    window.XRExtras.MediaRecorder.setCaptureMode('photo')
    window.XRExtras.MediaRecorder.initRecordButton() // Adds record button
    window.XRExtras.MediaRecorder.initMediaPreview() // Adds media preview and share
    customizePreview()
    addTracking()

    window.XR.run({ canvas: document.getElementById('ar-canvas') })

    const check = setInterval(() => {
      if (window.XRStarted) {
        this.running = true
        clearInterval(check)
        resolve()
      }
    }, 50)

  })

})()
