import React, { useRef, useGlobal, useEffect } from 'reactn'
import Location from 'views/Location'
import Gallery from 'views/Gallery'
import NotFound from 'views/NotFound'
import Intro from 'views/Intro'
// import Legals from 'views/Legals'
import Background from 'components/Background'
import Menu from 'components/Menu'
import Rotate from 'components/Rotate'
import { CSSTransition } from 'react-transition-group'
import ReactGA from 'react-ga'

import {
  Switch,
  Route,
  useLocation,
} from "react-router-dom";

function usePageViews() {
  let location = useLocation()
  useEffect(() => {
    ReactGA.pageview(location.pathname)
  }, [location]);
}

function App() {
  const location = useLocation()
  usePageViews()
  const menu = useRef(null)
  const [showMenu] = useGlobal('showMenu')

  return (
    <div className="App" data-location={location.pathname}>
      <Background />
      <Switch>
        <Route exact path="/">
        <Intro />
        </Route>
        <Route exact path="/location">
          <Location />
        </Route>
        <Route exact path="/gallery">
          <Gallery />
        </Route>
        {/* <Route path="/legal">
          <Legals />
        </Route> */}
        <Route path="/*">
          <NotFound />
        </Route>
      </Switch>
      <CSSTransition
        nodeRef={menu}
        in={ showMenu || location.pathname === '/legal' }
        timeout={300}
        classNames='fade'
        appear={true}
        unmountOnExit
      >
        <Menu ref={menu}/>
      </CSSTransition>
      { location.pathname !== '/legal' && (<Rotate/>) }
    </div>
  );
}

export default App;
