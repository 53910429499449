/* eslint-disable no-unused-vars */

import Logo from 'assets/svg/8thwall/8thwall.svg'
import CloseImg from 'assets/svg/8thwall/close-v2.svg'
// import ErrorLogo from 'assets/img/error-icon.png'
import ReactGA from 'react-ga'

import {isIOS, isMobileSafari, isChrome, isFirefox, isEdge} from 'react-device-detect';

export const customizeUI = () => {

//   const loadImage = document.getElementById("loadImage")
//   if (loadImage) {
//     loadImage.src='';
//     loadImage.style.display = 'none'
//     loadImage.classList.remove('spin')
//   }
  
  // powered by logo
  const images = document.querySelectorAll('.poweredby-img')
  for (let i = 0; i < images.length; i++) {
    images[i].src = Logo;
  }

//   //error
//   const error = document.querySelector('#cameraSelectionWorldTrackingError .floater')
//   error.src = ErrorLogo

}



export const customizePreview = () => {

  const close = document.querySelector('#closePreviewButton')
  close.innerHTML  = `<img src='${CloseImg}' />`

  const message = document.querySelector('#previewContainer .top-bar')
  message.insertAdjacentHTML('beforeend', '<div id="customMessage"><p>Tell your friends about Gallery&nbsp;K11.</p><p>Tag @houseofhoops and use the hashtag #DIYwithKai.</p></div>');
}

export const addTracking = () => {
  window.addEventListener('mediarecorder-photocomplete', () => {
    ReactGA.event({
      category: 'share',
      action: 'photo taken',
    });
  })
}