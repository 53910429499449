import React, { useEffect, useRef } from 'react'
import s from './Loader.module.scss'


export default function Loader(props) {
  const divRef = useRef(null)
  useEffect(() => {
    let fpsInterval, now, then, elapsed
    let currentFrame = 0
    const animationInEnd = 13
    const animationOutEnd = 29
    const positionStep = 150
    const midPauseFrames = 40
    const endPauseFrames = 11
    const totalFrames = animationOutEnd + midPauseFrames + endPauseFrames
    const FPS = 15
    let cancel = false
    function animate() {
      if (cancel) {
        return
      }
      requestAnimationFrame(animate)
      now = Date.now()
      elapsed = now - then
      if (elapsed > fpsInterval) {
        then = now - (elapsed % fpsInterval)
        currentFrame++
        if (currentFrame > totalFrames) {
          currentFrame = 0
        }
        var offset = currentFrame
        if (currentFrame >= totalFrames - endPauseFrames ) {
          offset = animationOutEnd
        } else if (currentFrame >= animationInEnd + midPauseFrames ) {
          offset = currentFrame - midPauseFrames
        } else if (currentFrame >= animationInEnd) {
          offset = animationInEnd
        } else {
          offset = currentFrame
        }
        if (divRef.current) {
          divRef.current.style.backgroundPosition = `-${offset * positionStep}px 0`
        }
      }
    }
    fpsInterval = 1000 / FPS
    then = Date.now()
    animate()
    return () => {
      cancel = true
    }
  }, [])

  return (
    <div ref={divRef} className={s.Loader}></div>
  )
}
