import React, { setGlobal } from 'reactn'
import ReactDOM from 'react-dom'
import 'styles/index.scss'
import App from './App'
import ReactGA from 'react-ga'
import { BrowserRouter as Router } from 'react-router-dom'
ReactGA.initialize('UA-182860481-1');
// ReactGA.pageview(window.location.pathname + window.location.search);

setGlobal({
  backgroundState: 'initial',
  currentPage: 'home',
  showCourt: false,
  showMenu: false,
})

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
// let vh = window.innerHeight * 0.01
// // Then we set the value in the --vh custom property to the root of the document
// document.documentElement.style.setProperty('--vh', `${vh}px`)


// window.addEventListener('resize', () => {
//   let vh = window.innerHeight * 0.01;
//   document.documentElement.style.setProperty('--vh', `${vh}px`);
//   setTimeout(() => {
//       let vh = window.innerHeight * 0.01;
//       document.documentElement.style.setProperty('--vh', `${vh}px`);
//     }, 400)
// })
