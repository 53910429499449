import React,  { useRef, useEffect, useGlobal } from 'reactn'
import s from './WelcomeContent.module.scss'
import { useHistory } from 'react-router-dom'
import Div100vh from 'react-div-100vh'
// import {ReactComponent as GalleryTitle} from '../../assets/svg/gallery-title.svg'
import {ReactComponent as Swoosh} from '../../assets/svg/swoosh.svg'
import {ReactComponent as Kyrie} from '../../assets/svg/kyrie.svg'
import {ReactComponent as FootLocker} from '../../assets/svg/foot-locker.svg'

import gsap from "gsap";

export default function WelcomeContent(props) {

  const introText = useRef(null)
  const introImage = useRef(null)
  const introTextP1 = useRef(null)
  const introTextP2 = useRef(null)
  const mainContent = useRef(null)
  const title = useRef(null)
  const logos = useRef(null)
  const startButton = useRef(null)
  const kyrieButton = useRef(null)
  const startContent = useRef(null)
  const [, setBackgroundState] = useGlobal('backgroundState')
  const [, setShowMenu] = useGlobal('showMenu')
  const [currentPage, setCurrentPage] = useGlobal('currentPage')
  const history = useHistory()
  const mainDiv = useRef(null)

  const enterTheGalleryClick = () => {
    const onComplete = () => {
      setCurrentPage('location')
      history.push('/location')
    }
    gsap.to(mainDiv.current, {opacity: 0, duration: 0.5, onComplete})
  }

  useEffect(() => {
    if (currentPage !== 'home') {
      window.location.reload()
    }
  // eslint-disable-next-line 
  }, [])  // I think it's fine to exclude currentPage because we only want the initial value of this

  useEffect(() => {
    const timer = setTimeout(() => {
      setBackgroundState('welcome')
      const introChildren = [
        introImage.current,
        introTextP1.current,
        introTextP2.current,
        logos.current,
      ]
      const startChildren = [
        title.current,
        startButton.current,
        kyrieButton.current,
      ]
      gsap.timeline({delay: 0.3})
        .to(introChildren, {opacity: 1, duration: 0.5, y: 0, stagger: 0.2})
        .to(introText.current, {delay: 3, opacity: 0, duration: 0.5})
        .set(introText.current, {display: 'none'})
        .call(() => {
          setBackgroundState('welcome-two')
        })
        .set(startContent.current, {display: 'flex'})
        .set(startChildren, {visibility: 'visible', opacity: 0, y: 10})
        .to(startChildren, {opacity: 1, y: 0, duration: 0.5, stagger: 0.2})
        .call(() => {
          setShowMenu(true)
        })
    }, 1500) 
    return () => clearTimeout(timer)
  }, [setBackgroundState, setShowMenu])

  // render() {
  return (
    <Div100vh className={s.WelcomeContent} ref={mainDiv}>
      <div className={s.copy} ref={introText}>
        <img ref={introImage} className={s.shoes} src={require('../../assets/images/shoes.png').default} alt="shoes"/>
        <p ref={introTextP1}>
          House of Hoops teamed up with six different artists to create Gallery K11 and design DIY 1 World/1 People sneaker packs.
        </p>
        <p ref={introTextP2}>
          Welcome to the only art gallery you can experience at a basketball court.
        </p>
      </div>
      <div className={s.startContent} ref={startContent}>
        <div className={s.mainContent} ref={mainContent}>
          <img className={s.galleryTitle} src={require('../../assets/images/gallery-title.png').default} alt="Gallery K11" ref={title}/>
        </div>
        <div className="button" ref={startButton} onClick={() => enterTheGalleryClick()}>
          Enter the Gallery
        </div>
        <a className="button outline" ref={kyrieButton} href="https://www.footlocker.com/category/collection/nike/kyrie.html" target="_blank" rel="noreferrer">
          <span>Cop a pair of kyries</span>          
        </a>
      </div>
      <div className={s.logos} ref={logos}>
        <FootLocker className={s.logo} />
        <div className={s.logoDivider}/>
        <Kyrie className={s.logo} />
        <div className={s.logoDivider}/>
        <Swoosh className={s.logo} />
      </div>
    </Div100vh>
  )
  // }
}
