import React, { forwardRef } from 'react'
import s from './About.module.scss'
import Div100vh from 'react-div-100vh'
import {ReactComponent as Kyrie} from '../../assets/svg/kyrie.svg'
import {ReactComponent as FootLocker} from '../../assets/svg/foot-locker.svg'


export default forwardRef((props, ref) => {

  return (
    <Div100vh ref={ref} className={s.About}>      
      <h1>about gallery k11</h1>
      <div className="divider"/>
      <p>Within this gallery you'll see artwork from established visual designers, rising youth creators, and renowned muralists. These artists are part of a movement to make art more accessible to all.</p>
      <p>As an open space for community to come together, basketball courts were the perfect venue to launch this digital gallery. Basketball courts are more than concrete squares and hoops — they’re places where the next generation can dream and play and create. And they're home to creative hoopers like Kyrie Irving.</p>
      <p>Gallery K11 is the only art gallery you can experience at a basketball court. But we hope it won't be the last. We hope you leave this experience inspired to create. We know that each of us is capable of bringing something beautiful and new into this world, something that makes it a better place.</p>
      <p>What will you create? #DIYwithKai</p>
      <div className="divider" />
      <div className={s.logos}>
          {/* <a href="https://www.footlocker.com/" target="_blank" rel="noreferrer"> */}
          <FootLocker className={s.logo} />
          {/* </a> */}
          {/* <a href="https://www.nike.com/w/kyrie-irving-shoes-892qazy7ok" target="_blank" rel="noreferrer"> */}
          <Kyrie className={s.logo} />
          {/* </a> */}
        </div>
    </Div100vh>
  )
})
