import React, {useEffect, useState, useGlobal, useRef} from 'reactn'
import s from './Location.module.scss'

import MainLayout from 'layouts/Main'
import AccessScreen from 'components/AccessScreen'
import CourtsList from 'components/CourtsList'
import CourtSelected from 'components/CourtSelected'
import Modal from 'components/Modal'
import { CSSTransition } from 'react-transition-group'



const Location = ({children}) => {

  const smallRadius = 100

  const [location, setLocation] = useState(null)
  const [hasResults, setHasResults] = useState(false)
  const [locationName, setLocationName] = useState(null)
  const [results, setResults] = useState([])
  const [currentPage] = useGlobal('currentPage')
  const prevLocation = usePrevious(location)
  const [showModal, setShowModal] = useState(false)
  const [curAccessScreen, setCurAccessScreen] = useState('initial')

  const nodeRef = useRef(null)
  const courtsListRef = useRef(null)
  const overlayRef = useRef(null)
  const courtSelectedRef = useRef(null)


  const loctationSuccess = ({coords}) => {
    const {latitude, longitude} = coords
    const userLocation = new window.google.maps.LatLng(Number(latitude),Number(longitude));
    setLocation(userLocation)
  }
  const loctationError = (err) => {
    // https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
    // err.code could be PERMISSION_DENIED, POSITION_UNAVAILABLE, or TIMEOUT
    console.warn(`ERROR(${err.code}): ${err.message}`)
    //seems like once this happens it's hard to get permission again without toggling system setting.
    setCurAccessScreen('rejected')

  }

  const getLocation = () => {
    console.log('getting location')
    navigator.geolocation.getCurrentPosition(loctationSuccess, loctationError)
  }

  const requestPlacesComplete = (results, status) => {
    if (status === window.google.maps.places.PlacesServiceStatus.OK && results.length > 0) {
      console.log('REQUEST COMPLETE: calling filter and set results')
      filterAndSetResults(results)
      setHasResults(true)
    } else {
      console.log('ERROR', results, status)
      filterAndSetResults([{name: 'No Results'}])
      setHasResults(true)
    }
  }

  const filterAndSetResults = (results) => {
    console.log('filterAndSetResults')
    console.log(results)
    // const mapped = results.map(({name, geometry: {location: resultCoords}}, types) => {
    const mapped = results.map(({name, geometry, types}) => {
      // console.log(userLocation.lat(), userLocation.lng())
      // const resultCoords = new window.google.maps.LatLng(location)
      const meters = window.google.maps.geometry.spherical.computeDistanceBetween(location, geometry.location)
      const distance = Math.round(meters / 1609 * 10) / 10
      return {
        geometry,
        meters,
        distance,
        name,
        types
      }
    }).filter(({types}) => !types.includes('store'))
    mapped.sort((a, b) => { return a.meters - b.meters } )
    // if (mapped)
    if (mapped.length === 0) {
      // TODO: handle the case where there are not results. Tell user and let them handle it.
    } else if (mapped[0].meters < smallRadius ) {
      setLocationName(mapped[0].name)
    } else if (prevLocation !== null && location !== prevLocation) {
      // this is the case where it's not the first time they've checked their location
      setShowModal(true)
    }
    setResults(mapped)
  }

  const handleCantFindCourt = () => {
    // show the no court screen
    onCloseModal()
    setHasResults(false)
    setCurAccessScreen('no-location')
  }

  const onCloseModal = () => {
    setShowModal(false)
  }

  useEffect(() => {
    console.log(`currentPage = ${currentPage}`)
    if (currentPage !== 'location') {
      window.location.href = `${window.location.origin}/`
    }
  })

  useEffect(() => {
    // is this logic right? or will it cause an issue when a 0 length results array is returned?
    if (location !== prevLocation && hasResults && results.length > 0) {
      console.log('calling filter and set results')
      filterAndSetResults(results)
    }
    else if (location && prevLocation === null) {
      const request = {
        location,
        // radius: 800,
        keyword: 'basketball court',
        rankBy: window.google.maps.places.RankBy.DISTANCE,
      };
      const service = new window.google.maps.places.PlacesService(document.createElement('div'));
      service.nearbySearch(request, requestPlacesComplete);
    }
  // it was complaining about 'filterAndSetResults', 'prevLocation', and 'requestPlacesComplete' not being in dependency array, but including them breaks stuff. Not totally sure the best way to deal with it.
  // eslint-disable-next-line 
  }, [location, hasResults, results])
  
  const modalContent = {
    copy: [
      'Looks like you’re not close enough to the court yet.',
      'Keep going!<br/>You’re almost there.',
    ],
    button: {
      label: 'Not At a Court?',
      callback: handleCantFindCourt,
    },
  }
  return (
    <MainLayout>
      <div className={s.Location}>
        <CSSTransition
          nodeRef={nodeRef}
          in={!hasResults}
          timeout={{appear: 900, enter:900, exit: 300}}
          classNames='access'
          appear={true}
          unmountOnExit
        >
          <AccessScreen callback={getLocation} screen={curAccessScreen} ref={nodeRef}/>
        </CSSTransition>
        <CSSTransition 
          nodeRef={courtsListRef} 
          in={hasResults && results.length && locationName === null} 
          timeout={{appear: 1000, enter:1000, exit: 300}} 
          classNames='courts-list' 
          unmountOnExit
        >
          <CourtsList results={results} callback={getLocation} ref={courtsListRef}/>
        </CSSTransition>
        {/* { locationName !== null && (<CourtSelected court={locationName}/>)} */}
        <CSSTransition
          nodeRef={courtSelectedRef} 
          in={ locationName !== null }
          timeout={{appear: 900, enter:900, exit: 300}}
          classNames='court-selected'
          appear={true}
          unmountOnExit
        >
          <CourtSelected ref={courtSelectedRef} court={locationName}/>
        </CSSTransition>
        <CSSTransition nodeRef={overlayRef} in={showModal}  timeout={300} classNames='fade-up' appear={true} unmountOnExit>
          <Modal ref={overlayRef} copy={modalContent.copy} button={modalContent.button} closeHandler={onCloseModal}/>
        </CSSTransition>
      </div>
    </MainLayout>
  )
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default Location
