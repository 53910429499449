import React, { useEffect, useState, useGlobal } from 'reactn'
import s from './Gallery.module.scss'
import cx from 'classnames'
import Div100vh from 'react-div-100vh'

import ExperienceLoader from 'scripts/loaders/ExperienceLoader'
import ARLoader from 'scripts/loaders/ARLoader'
import EventBus from 'scripts/util/EventBus';

import MainLayout from 'layouts/Main'
import Background from 'components/Background'
import Modal from 'components/Modal'
// import {ReactComponent as Swoosh} from '../../assets/svg/swoosh.svg'
import Loader from 'components/Loader'

const Gallery = ({children}) => {

  const [loading, setLoading] = useState(true);
  const [assetsLoaded, setAssetsLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showCourt] = useGlobal('showCourt')
  const [currentPage] =  useGlobal('currentPage')
  const [, setBackgroundState] = useGlobal('backgroundState')

  useEffect( () => {
    if(assetsLoaded === true) {
      document.body.classList.add('assetsLoaded')
    }
  }, [assetsLoaded])
  useEffect(() => {
    setBackgroundState('gallery')
  }, [setBackgroundState])

  useEffect(() => {
    if (currentPage !== 'gallery') {
      window.location.href = `${window.location.origin}/`
      return
    }
    EventBus.on('Action', handleAction)
    ARLoader.load().then(() => {
      ExperienceLoader.load("Main").then(onLoadModule)     
    })
  }, []) // eslint-disable-line

  const onLoadModule = (module) => {
    const experience = new module.default()
    const canvas = document.getElementById('ar-canvas')
    experience.initPipeline(canvas)
    experience.load().then(() => {
      setAssetsLoaded(true)
      experience.initExperience(showCourt)
      startExperience()
    })
  }

  const startExperience = () => {
    window.addEventListener('mediarecorder-previewclosed', handlePreviewClosed)
    window.addEventListener('mediarecorder-previewopened', handlePreviewOpened)
  }

  const handlePreviewClosed = () => {
    document.body.classList.remove('preview-open')
    setShowModal(true)

  }
  const handlePreviewOpened = () => {
    document.body.classList.add('preview-open')
  }

  const handleAction = (payload) => {
    console.log('Event:', payload)
    if(payload.type === 'loadingDone') {
      setLoading(false);
    }
  }

  const buyShoeCTA = () => {
    onCloseModal()
    window.open('https://www.footlocker.com/category/collection/nike/kyrie.html')
  }

  const onCloseModal = () => {
    setShowModal(false)
  }

  const modalContent = {
    copy: [
      'Thanks for visiting<br>Gallery K11!',
      'Want to be part of the DIY movement? Cop a pair of Kyries now.',
    ],
    button: {
      label: 'Buy Kyrie DIY',
      callback: buyShoeCTA,
    },
  }

  return (
    <MainLayout>
      <Div100vh className={cx(s.Gallery, { [s.assetsLoaded]: assetsLoaded })}>
        {loading && assetsLoaded !== true && <Background /> }
        {loading && assetsLoaded !== true && <div className={s.Loading}>
          {/* <h1>Loading Experience</h1>   */}
          <Loader />
        </div>}
        { showModal && (<Modal copy={modalContent.copy} button={modalContent.button} closeHandler={onCloseModal}/>)}
      </Div100vh>
    </MainLayout>
  )

}

export default Gallery
