import React, { useRef, useGlobal, useState, useEffect } from 'reactn'
import s from './Background.module.scss'
import Div100vh from 'react-div-100vh'
import {ReactComponent as TealSwoop} from '../../assets/svg/teal-swoop.svg'
import {ReactComponent as GrayBlocker} from '../../assets/svg/gray-blocker.svg'
import {ReactComponent as RedChecker} from '../../assets/svg/red-checker.svg'
import {ReactComponent as Swoosh} from '../../assets/svg/swoosh.svg'
// import gsap from "gsap";

export default function Background(props) {

  const topRight = useRef(null)
  const bottomLeft = useRef(null)
  const swoosh = useRef(null)
  const altRedSwoop = useRef(null)
  const [backgroundState] = useGlobal('backgroundState')
  const [imagesLoaded, setImagesLoaded] = useState(false)
  const [loadCount, setLoadCount] = useState(0)
  const imageCount = 4

  // useEffect(() => {
  //   setTimeout(startAnimation, 1000)
  // })

  // function startAnimation() {
  //   gsap.timeline()
  //     .set(topRight.current, {transformOrigin: '450px 200px'})
  //     .to(topRight.current, {x:33, y: -239, duration: 0.5})
  //     .to(topRight.current, {rotate: -32, duration: 0.5}, '-=0.5' )

  //   gsap.timeline()
  //     .to(bottomLeft.current, {x: -115, y: 100, duration: 0.5})
  //     .to(altRedSwoop.current, {x: 0, y: 0, duration: 0.5}, '-=0.5' )

  //   gsap.timeline()
  //     .to(swoosh.current, {scale: 8, opacity: 0, duration: 0.5})
  
  // }

  function onImageLoad() {
    console.log('onImageLoad')
    setLoadCount(loadCount + 1)
  }

  useEffect(() => {
    if (loadCount === imageCount) {
      setImagesLoaded(true)
    }
  }, [loadCount, setImagesLoaded])


  return (
    <Div100vh className={`${s.Background} ${s[backgroundState]} ${imagesLoaded ? s.loaded : ''}`}>
      <div className={s['bottom-left-container']} ref={bottomLeft}>
        <div className={s['blocker-and-blue-swoop']}>
          <img onLoad={ onImageLoad } src={require('../../assets/images/blue-swoop.png').default} className={s['blue-swoop']} alt=""/>
          <GrayBlocker className={s['gray-blocker']} />
        </div>
        <RedChecker className={s['red-checker']} />
      </div>
      <div className={s['top-right-container']} ref={topRight}>
        <img onLoad={ onImageLoad } src={require('../../assets/images/red-swoop.png').default} className={s['red-swoop']} alt=""/>
        <TealSwoop className={s['teal-swoop']} />
        <img onLoad={ onImageLoad } src={require('../../assets/images/red-swoop-alt-2.png').default} className={s['red-swoop-alt']} alt=""/>
      </div>
      <Swoosh className={s.swoosh} ref={swoosh}/>
      <img onLoad={ onImageLoad } src={require('../../assets/images/red-swoop-alt-2.png').default} className={s.altRedSwoop} ref={altRedSwoop} alt=""/>
    </Div100vh>
  )
}
