import React, { useState, useGlobal, forwardRef } from 'reactn'
import { useHistory } from 'react-router-dom'
import s from './AccessScreen.module.scss'
import Div100vh from 'react-div-100vh'
import {ReactComponent as CameraIcon} from '../../assets/svg/camera-icon.svg'
import {ReactComponent as PinIcon} from '../../assets/svg/pin-icon.svg'
import {ReactComponent as MicIcon} from '../../assets/svg/mic-icon.svg'
import { useEffect } from 'react'


// export default function AccessScreen(props) {
export default forwardRef((props, ref) => {

  const [screen, setScreen] = useState(props.screen)
  const [, setBackgroundState] = useGlobal('backgroundState')
  const history = useHistory()
  const [, setCurrentPage] = useGlobal('currentPage')
  const [, setShowCourt] = useGlobal('showCourt')

  // const foo = useRef(null)

  useEffect(() => {
    setScreen(props.screen)
  }, [props.screen])

  useEffect(() => {
    setBackgroundState('no-top')
  }, [setBackgroundState])

  function onContinueClick() {
    props.callback()
    // setScreen('rejected')
  }

  function onRestartClick() {
    window.location.href = `${window.location.origin}/`
  }

  function onContinueAnywayClick() {
    startGallery()
  }

  function onLaunchClick() {
    startGallery()
  }

  function startGallery() {
    setShowCourt(true)
    setCurrentPage('gallery')
    history.push('/gallery')
  }

  return (
    <Div100vh ref={ref} className={s.AccessScreen}>
      { screen === 'initial' && (
        <div className={s.mainContent}>
          <div className={`${s.copy} ${s.longCopy}`}>
            <p>To get the full experience of Gallery K11, please allow temporary access to your camera, microphone, and&nbsp;location.</p>
          </div>
          <div className={s.icons}>
            <PinIcon className={s.icon}/>
            <CameraIcon className={s.icon}/>
            <MicIcon className={s.icon}/>
          </div>
          <div className="button" onClick={onContinueClick}>
            Continue
          </div>
        </div>
      )}
      { screen === 'rejected' && (
        <div className={s.failContent}>
          <div className={s.copy}>
            <p>To launch the immersive Gallery K11 experience, we need to access your device’s camera and geo location. </p>
          </div>
          <div className="button" onClick={onRestartClick}>
            Restart The Experience
          </div>
          <div className="button outline" onClick={onContinueAnywayClick}>
            <span>Continue Without Location</span>
          </div>
        </div>
      )}
      { screen === 'no-location' && (
        <div className={s.noLocationContent}>
          <div className={s.copy}>
          <p>Art is for everyone to make and enjoy.</p>
          <p>While Gallery K11 is best experienced on the court, you can still browse the gallery wherever you are.</p>
          {/* <p>Art is for everyone to make and enjoy. And we’d love for you to experience Gallery K11 in the wild.</p>
          <p>If you’re not close to a court, you can still see the art.</p> */}
          </div>
          <div className="button" onClick={onLaunchClick}>
            Launch The Gallery
          </div>
        </div>
      )}
    </Div100vh>
  )
})
