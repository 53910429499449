import React from 'reactn'
import s from './Intro.module.scss'
import Div100vh from 'react-div-100vh'

import MainLayout from 'layouts/Main'
import WelcomeContent from 'components/WelcomeContent'

const Intro = ({children}) => {

  return (
    <MainLayout>
      <Div100vh className={s.Intro}>
        <WelcomeContent/>
      </Div100vh>
    </MainLayout>
  )

}

export default Intro
