import React, { forwardRef } from 'react'
import s from './CourtsList.module.scss'
import Div100vh from 'react-div-100vh'
import {ReactComponent as SmallPin} from '../../assets/svg/small-pin.svg'


// export default function CourtsList(props) {
export default forwardRef((props, ref) => {

  // const [selected, setSelected] = useState(0)

  // function onCourtClick(index) {
  //   setSelected(index)
  // }

  function onContinueClick() {
    // props.callback(props.results[selected].name)
    props.callback()
  }

  return (
    <Div100vh className={s.CourtsList} ref={ref}>      
        <div className={s.mainContent}>
          <div className={s.copy}>
            {/* <p>Head to the nearest court to enjoy the art. We’ll see you outside!</p> */}
            <p>We found a few courts nearby. Head to a basketball court, and click the button to confirm your&nbsp;location.</p>
          </div>
          <div className={s.resultsContainer}>
            {props.results.map(({name, distance}, index) => (
              <div className={s.result} key={`${name}${index}`}>
                <SmallPin className={s.pin}/>
                <div className={s.resultLabel}>
                  {name}
                </div>
                <div className={s.distance}>{distance} mi</div>
                {/* <div className={s.distance}>2 mi</div> */}
              </div>
            ))}
          </div>
          <div className="button" onClick={onContinueClick}>
            Confirm Location
          </div>
          {/* <div className="button outline" onClick={onCantFindCourtClick}>
            <span>Can't Find a Court?</span>
          </div> */}
      </div>
    </Div100vh>
  )
})
